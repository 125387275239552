/// <reference path="../../typings/index.d.ts" />
/// <reference path="suxesiv/slider.ts" />
/// <reference path="suxesiv/lightbox.ts" />
///<reference path="suxesiv/responsive-collapse.ts"/>
///<reference path="suxesiv/mobile-dropdown-navi.ts"/>

declare function flexibility(el: any): void;

interface Document {
    documentMode: number;
}

interface jQuery {
    smoothScroll():any;
}

namespace Suxesiv {

    export class Main {
        /**
         * @type {Suxesiv.Slider[]}
         */
        protected sliders: Slider[];

        public constructor() {
            this.sliders = [];
        }

        protected svgFallback() {
            if (!Modernizr.svg) {
                $('body').addClass('svg_fallback');

                let $images = $('img[src]');


                $images.each(function (i, image) {
                    let $image = $(image);
                    let src = $image.attr('src');
                    let regex = /(^.*)(\.svgz?)(\?.*)?$/img;
                    if (src.match(regex)) {
                        $image.attr('src', $image.attr('src').replace(regex, '$1.png$3'));
                    }

                });
            }
        }

        protected flexboxFallback() {
            if (!Modernizr.flexbox && typeof flexibility !== "undefined") {

                $('body').removeClass('no-flexbox'); // remove class, so css fallback will not be applied
                flexibility(document.documentElement);

                let onresizeTimeout: any = null;

                let onresize = function () {
                    window.onresize = function () {
                    };

                    if (!onresizeTimeout) {
                        onresizeTimeout = setTimeout(function () {
                            onresizeTimeout = null;

                            flexibility(document.documentElement);

                            window.onresize = onresize;
                        }, 1000 / 60);
                    }
                }
                window.onresize = onresize;

            }
        }


        public setup() {
            this.svgFallback();
            //this.flexboxFallback();

            this.setupSlider();
            //this.setupLightbox();
            this.setupMobileDropdownNav();
            this.setupResponsiveCollapse();
            this.setupSmoothScroll();

            // wanna see if you tested it on older IE's ;-)
            if ("documentMode" in document) {
                if (document.documentMode < 11) {
                    alert('Super! Du testest die Templates im IE! Du kannst nun diese Meldung in der Datei "suxesiv.ts" deaktivieren!');
                }
            }
        }

        protected setupSmoothScroll() {
            $('a[href^="#"]').smoothScroll({offset: $('.navbar').height()});
        }

        protected setupSlider() {
            $('.owl-carousel').each(function (index: number, element: Node) {
                this.sliders.push(new Suxesiv.Slider(element));
            }.bind(this));
        }

        protected setupLightbox() {
            new Suxesiv.Lightbox('.page-content');
        }

        protected setupMobileDropdownNav() {
            $('[data-mobile-dropdown-nav=true]').each(function (index, el) {
                new MobileDropdownNav(el, 768);
            });
        }

        protected setupResponsiveCollapse() {
            $('.responsive-collapse').each(function (i, el) {
                let $el = $(el);
                new ResponsiveCollapse($el, $el.data('responsive-toggle') || $el, parseInt($el.data('responsive-threshold')) || 768);
            });
        }
    }
}
